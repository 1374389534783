// @flow
/* global document Image */
export default class FaviconBadge {
  constructor(site) {
    Object.assign(
      this, {
        backgroundColor: '#f00',
        size: 0.3, // 0..1 (Scale in respect to the favicon image size)
        position: 'ne', // Position inside favicon "n", "e", "s", "w", "ne", "nw", "se", "sw"
        radius: site === 'htz' ? 10 : site === 'tm' ? 3 : 5, // Border radius
        src: '', // Favicon source (dafaults to the <link> icon href)
        onChange() {},
      },
    );
    this.canvas = document.createElement('canvas');
    this.faviconEL = document.querySelector('link[rel$=icon]');
    this.src = this.src || this.faviconEL.getAttribute('href');
    this.ctx = this.canvas.getContext('2d');
  }

  drawIcon() {
    this.ctx.clearRect(0, 0, this.faviconSize, this.faviconSize);
    this.ctx.drawImage(this.img, 0, 0, this.faviconSize, this.faviconSize);
  }

  drawShape() {
    const r = this.radius;
    const xa = this.offset.x;
    const ya = this.offset.y;
    const xb = this.offset.x + this.badgeSize;
    const yb = this.offset.y + this.badgeSize;
    this.ctx.beginPath();
    this.ctx.moveTo(xb - r, ya);
    this.ctx.quadraticCurveTo(xb, ya, xb, ya + r);
    this.ctx.lineTo(xb, yb - r);
    this.ctx.quadraticCurveTo(xb, yb, xb - r, yb);
    this.ctx.lineTo(xa + r, yb);
    this.ctx.quadraticCurveTo(xa, yb, xa, yb - r);
    this.ctx.lineTo(xa, ya + r);
    this.ctx.quadraticCurveTo(xa, ya, xa + r, ya);
    this.ctx.fillStyle = this.backgroundColor;
    this.ctx.fill();
    this.ctx.closePath();
  }

  drawFavicon() {
    this.faviconEL.setAttribute('href', this.dataURL);
  }

  draw(add) {
    this.drawIcon();
    if (add) this.drawShape();
    this.drawFavicon();
  }

  setup() {
    this.faviconSize = this.img.naturalWidth;
    this.badgeSize = this.faviconSize * this.size;
    this.canvas.width = this.faviconSize;
    this.canvas.height = this.faviconSize;
    const sd = this.faviconSize - this.badgeSize;
    const sd2 = sd / 2;
    this.offset = {
      n: { x: sd2, y: 0, },
      e: { x: sd, y: sd2, },
      s: { x: sd2, y: sd, },
      w: { x: 0, y: sd2, },
      nw: { x: 0, y: 0, },
      ne: { x: sd, y: 0, },
      sw: { x: 0, y: sd, },
      se: { x: sd, y: sd, },
    }[this.position];
  }

  update(add) {
    if (this.img) {
      this.draw(add);
      if (this.onChange) this.onChange.call(this);
    }
    else {
      this.img = new Image();
      this.img.addEventListener('load', () => {
        this.setup();
        this.draw(add);
        if (this.onChange) this.onChange.call(this);
      });
      this.img.src = this.src;
    }
  }

  get dataURL() {
    return this.canvas.toDataURL();
  }

  addBadge() {
    if (this.added) return;
    this.update(true);
    this.added = true;
  }

  removeBadge() {
    if (!this.added) return;
    this.update(false);
    this.added = false;
  }
}

const regex = /^\(\d*\) /;

function alreadyHasNumberInTitle(title) {
  return title.match(regex);
}

function updateNumberInTitle(number) {
  if (document) {
    document.title = document.title.replace(regex, `(${number}) `);
  }
}

export function setNumberInTitle(number) {
  if (alreadyHasNumberInTitle(document.title)) { // already has a number in the title
    updateNumberInTitle(number);
  }
  else if (document) {
    document.title = `(${number}) ${document.title}`; // new number
  }
}

export function clearNumberInTitle() {
  if (document) {
    document.title = document.title.replace(regex, '');
  }
}
