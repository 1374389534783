/* global window document */
// @flow
import * as React from 'react';
import { FelaComponent, } from 'react-fela';
// import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter, } from 'next/router';
import { useApolloClient, } from 'react-apollo';
import AriaLive from '../AriaLive/AriaLive';
import DeviceTypeInjector from '../DeviceTypeInjector/DeviceTypeInjector';
import RouteChangeListener from '../EventListeners/RouteChangeListener';
import UserInjector from '../User/UserInjector';
import PermutiveHomePage from '../Scripts/Permutive/PermutiveHomePage';
import GaSectionPage from '../GoogleAnalytics/GaSectionPage';
import OmnyDataCheck from '../AudioPlayerWithActions/OmnyPodcast/OmnyDataCheck';
import NoSSR from '../NoSSR/NoSSR';
import Scripts from '../Scripts/Scripts';
import redirect from '../../utils/redirect';
import WidePageLayoutDataGetter from './WidePageLayoutDataGetter';
import useSeoData from '../../hooks/Page/useSeoData';
import { useIsBot, } from '../../hooks/useIsBot';
import useBreadcrumbsData from '../../hooks/Page/useBreadcrumbsData';
import { AnalyticsBS, } from '../AnalyticsBS/AnalyticsBS';
import { buildNextUrl, } from '../HtzLink/HtzLink';
import SectionAndHomepageSEO from '../SEO/SectionAndHomepageSEO';
import { useEventTracker, } from '../../utils/EventTracker';
import useOneTime from '../../hooks/useOneTime';
import FaviconBadge from '../../utils/FaviconBadge';
import checkSiteFromConfig from '../../utils/checkSiteFromConfig';

type Props = {
  render: Function,
  isGroupS: boolean,
  responseWriteHead: ?Function,
  responseEnd: ?Function,
  autoRefresh: boolean,
  canonicalUrlOverride: ?string,
  pageTypeOverride: ?string,
  organizationSchema?: ?string,
  data: Object,
}

function WidePageLayout({
  render,
  isGroupS,
  autoRefresh,
  canonicalUrlOverride,
  pageTypeOverride,
  responseWriteHead,
  responseEnd,
  organizationSchema,
  data,
}: Props): React.Node {
  const router = useRouter();
  const { biAction, } = useEventTracker();
  const path = (router && router.query.path) || '/';
  const asPath = (router && router.asPath) || '/';
  const client = useApolloClient();

  const { siteAlias, } = checkSiteFromConfig();

  const { isBot, } = useIsBot();
  const {
    pageType,
    pageDateTimeString,
  } = data?.Page || {};
  const redirectUri = data?.Page?.redirect;
  const seoData = useSeoData();
  const lineage = useBreadcrumbsData();
  const globalLazyload = false; // TODO: not exist

  const isLabel = path.includes('/labels') || path.includes('/haaretz-labels');
  useOneTime(typeof window !== 'undefined' && window.localStorage.getItem('refresh') && typeof biAction === 'function' && biAction.length > 0, () => {
    biAction({
      actionCode: 4,
      feature: 'Auto refresh page',
      featureType: 'Content',
    });
    window.localStorage.removeItem('refresh');

    // BADGE: initiate
    const badger = new FaviconBadge(siteAlias);

    // BADGE: remove badge on page focus
    const handler = () => {
      const mainArticleAnchor = document.querySelector('[data-main="mainBlock"] a');
      badger.removeBadge();
      if (mainArticleAnchor) {
        const currentMainHref = mainArticleAnchor.href;
        window.sessionStorage.setItem('mainArticleHref', currentMainHref);
        window.removeEventListener('focus', handler);
      }
    };
    window.addEventListener('focus', handler);

    // BADGE: if the document is hidden, check the session storate key to see if main article href has changed, if yes - add the badge
    if (typeof document !== 'undefined' && new URL(document.location.href).pathname === '/' && document.hidden) {
      const mainArticleAnchor = document.querySelector('[data-main="mainBlock"] a');
      if (mainArticleAnchor) {
        const currentMainHref = mainArticleAnchor.href;
        const previousMainHref = window.sessionStorage.getItem('mainArticleHref');
        if (currentMainHref !== previousMainHref) {
          badger.addBadge();
        }
      }
    }
  });

  if (redirectUri) {
    if (responseEnd && responseWriteHead) {
      const { computedHref, computedPathname, } = buildNextUrl(redirectUri);
      const qs = ((asPath || '').match(/\?(.*)/) || [])[1];
      const redirectUriWithQS = qs
        ? `${redirectUri}${redirectUri.includes('?') ? '&' : '?'}${qs}`
        : redirectUri;

      redirect(
        responseEnd,
        responseWriteHead,
        redirectUriWithQS,
        301,
        computedHref,
        computedPathname
      );
    }
    return null;
  }

  const sectionId = Array.isArray(lineage) ? lineage[0].contentId : null;

  client.writeData({
    data: {
      globalLazyload,
      title: seoData && seoData.metaTitle,
      pageDateTimeString,
      pageType: pageTypeOverride || pageType,
      path,
      isBot: isBot || null,
      sectionId,
      // place properties to reset in the client store when a new article is loaded
      isOsakaDisplayed: false,
      canonicalUrl: seoData && seoData.canonicalUrl,
      isLabel,
    },
  });
  return (
    <React.Fragment>
      <SectionAndHomepageSEO />
      <Head>
        {/*
               * Refresh page every 5 mins
               * TODO: Only refresh changed items and ads
               */}
        {autoRefresh && (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
            window.__HTZ = window.__HTZ || {};
try {
  if (typeof window.__HTZ.cancelRefresh === 'function') {
    window.__HTZ.cancelRefresh();
  }
  if (window.matchMedia("(min-width: 37.5em)").matches) {
    var refreshTimer = setTimeout(
      function refreshWindow() {
        typeof window != 'undefined' &&  window.localStorage.setItem('refresh', 'true');
        typeof window != 'undefined' &&  window.location.reload()
      },
      ${1000 * 60 * 5} // BADGE: reduce numbers to increase refresh rates for testing, original is 1000 * 60 * 5 = ${1000 * 60 * 5}
    );
    window.__HTZ.cancelRefresh = function cancelRefresh() {
      clearTimeout(refreshTimer);
      window.__HTZ.cancelRefresh.activated = true;
      window.localStorage.removeItem('refresh');
    };
  }
}
catch (err) {console.log(err)}`,
          }}
        />
        )}

        { organizationSchema
              && (
              <script
                type="application/ld+json"
                data-schema="Organization"
                dangerouslySetInnerHTML={{ __html: organizationSchema, }}
              />
              )}
      </Head>
      <RouteChangeListener />
      <UserInjector />
      <NoSSR>
        {/* <GaSectionPage /> */}
        <OmnyDataCheck />
      </NoSSR>
      <React.Fragment>
        <AriaLive />
        <DeviceTypeInjector />
        <div id="maavaron" />
        <h1>{isGroupS}</h1>
        <FelaComponent
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          {render({ globalLazyload, })}
        </FelaComponent>
      </React.Fragment>
      <Scripts scriptTag="permutive">
        <PermutiveHomePage />
      </Scripts>
      <AnalyticsBS />
    </React.Fragment>
  );
}

WidePageLayout.defaultProps = {
  autoRefresh: true,
  canonicalUrlOverride: null,
  pageTypeOverride: null,
  responseWriteHead: null,
  responseEnd: null,
  organizationSchema: null,
};

export default function (props: Object) {
  return <WidePageLayoutDataGetter>{({ data, }) => <WidePageLayout {...props} data={data} />}</WidePageLayoutDataGetter>;
}
