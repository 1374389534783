/* global window document */
// @flow

import React from 'react';
import { useQuery, } from 'react-apollo';
import { SECTION_QUERY, } from '@haaretz/graphql';
import log from 'loglevel';
import { useRouter, } from 'next/router';
import Error from '../Error/Error';
import useOneTime from '../../hooks/useOneTime';

type WidePageLayoutDataGetterProps = {
  children: Function,
}

export default function WidePageLayoutDataGetter({ children, }: WidePageLayoutDataGetterProps) {
  const router = useRouter();
  const path = (router && router.query.path) || '/';

  const variables = router.query.previewId && path !== '/' ? { id: router.query.previewId, } : { path, };

  const { data, error, loading, } = useQuery(SECTION_QUERY, { variables, });

  // BADGE: set a session storage key with main article href
  useOneTime(typeof window !== 'undefined' && typeof document !== 'undefined' && new URL(document.location.href).pathname === '/' && !window.sessionStorage.getItem('mainArticleHref'), () => {
    const mainArticleAnchor = document.querySelector('[data-main="mainBlock"] a');

    const handler = () => {
      window.sessionStorage.setItem('mainArticleHref', mainArticleAnchor.href);
      window.removeEventListener('focus', handler);
    };

    if (mainArticleAnchor) {
      window.sessionStorage.setItem('mainArticleHref', mainArticleAnchor.href);
      window.addEventListener('focus', handler);
    }
  });

  if (loading) return null;
  if (error) {
    if (
      error.graphQLErrors[0]
            && error.graphQLErrors[0].extensions
            && error.graphQLErrors[0].extensions.response
            && error.graphQLErrors[0].extensions.response.status
            && typeof error.graphQLErrors[0].extensions.response.status === 'number'
    ) {
      return (
        <Error
          errorCode={error.graphQLErrors[0].extensions.response.status}
          kind="error"
          message="Brighspot is down, Call Avi Kaufman."
          path={path}
        />
      );
    }
    return <Error path={path} errorCode={404} kind="error" message="Brighspot is down, Call Avi Kaufman." />;
  }
  if (!data?.Page) {
    log.error(`no data for path '${path}'`);
    return <Error path={path} errorCode={404} kind="error" message="Something went wrong. Call Eran Meshulam." />;
  }

  if (typeof children !== 'function') return null;

  return children({ data, });
}
